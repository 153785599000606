<template>
        <div
                v-if="page_settings"
                class="wrapper_home">

                <loading v-if="!this.$store.getters.loading" />

                <headerBg data-aos-duration="2000" data-aos-delay="200" data-aos="fade-up" :white="true"
                        :title="page_settings.header_title" :description="page_settings.header_description"
                        :btn="true" :down="true" :bg="'https://api.interchim.bg/storage/' + page_settings.header_bg"
                        :btnLink="'/' + locale  + page_settings.banner_button_link"
                        :btnTitle="page_settings.banner_button_text" />

                <div class="home">

                        <section-rigt-left data-aos-duration="2000" data-aos-delay="200" data-aos="zoom-in"
                                :img="data.about_us.media.image[0].default"
                                :subtitle="data.page_settings.about_subtitle" :title="data.about_us.sub_title"
                                :description="data.about_us.description" :btn-title="data.header_footer.vocabulary.btn_read_more"
                                :btn-link="'/' + locale + '/' + data.about_us.slug" />

                        <product-wrapper data-aos-duration="2000" data-aos-delay="200" data-aos="zoom-in"
                                :title="data.page_settings.products_title"
                                :description="data.page_settings.products_desctipion">

                                <product-item data-aos-duration="2000" data-aos-delay="200" data-aos="zoom-in"
                                        v-for="products in data.products" :key="products.title"
                                        :img="products.media.icon[0].default" :title="products.title"
                                        :description="products.short_description" :btnTitle = 'data.header_footer.vocabulary.btn_read_more'
                                        :btn-link="'/' + locale + data.header_footer.menu[2].slug + '/' + products.slug" />

                        </product-wrapper>

                        <section-rigt-left data-aos-duration="2000" data-aos-delay="200" data-aos="zoom-in"
                                :img="data.methylamine.media.image[0].default"
                                :subtitle="data.page_settings.about_subtitle" :title="data.methylamine.sub_title"
                                :description="data.methylamine.description" :btn-title="data.header_footer.vocabulary.btn_read_more"
                                :btn-link="'/' + locale + '/' + data.methylamine.slug" :reverse="true" />

                        <benefits-wrapper data-aos-duration="2000" data-aos-delay="200" data-aos="zoom-in"
                                :title="data.page_settings.benefits_title"
                                :description="data.page_settings.benefits_description">

                                <benefits-item data-aos-duration="2000" data-aos-delay="200" data-aos="zoom-in"
                                        v-for="benefits in data.benefits" :key="benefits.title" :title="benefits.title"
                                        :description="benefits.description" :img="benefits.media.icon[0].default" />

                        </benefits-wrapper>

                        <benefits-wrapper data-aos-duration="2000" data-aos-delay="200" data-aos="zoom-in"
                                :title="data.page_settings.laboratories_title"
                                :description="data.page_settings.laboratories_description">
                                <div class="item">

                                        <partnersItem data-aos-duration="2000" data-aos-delay="200" data-aos="zoom-in"
                                                v-for="partners in data.laboratories" :key="partners.title"
                                                :img="partners.media.image[0].default" :title="partners.title" />


                                </div>
                        </benefits-wrapper>

                </div>
        </div>
</template>

<script>
        import {
                RepositoryFactory
        } from "../api/RepositoryFactory";
        const GetRepository = RepositoryFactory.get('data');

        import loading from '../components/loading'
        import partnersItem from "../components/partners/partnersItem";
        import headerBg from '../components/app/headerBg'
        import SectionRigtLeft from "../components/home/sectionRigtLeft";
        import ProductWrapper from "../components/product/productWrapper";
        import ProductItem from "../components/product/productItem";
        import BenefitsWrapper from "../components/benefits/benefitsWrapper";
        import BenefitsItem from "../components/benefits/benefitsItem";

        export default {
                name: 'Home',
                locale: window.localStorage.getItem('locale'),

                components: {
                        BenefitsItem,
                        BenefitsWrapper,
                        ProductItem,
                        ProductWrapper,
                        SectionRigtLeft,
                        headerBg,
                        partnersItem,
                        loading
                },

                async mounted() {
                        await this.fetch();
                },

                data() {
                        return {
                                isLoading: false,
                                locale: this.$store.state.locale,
                                data: {},
                                page_settings: '',
                                title: null,
                                meta: [],
                                og: []
                        }
                },

                watch: {
                        '$route.params.slug': {
                                immediate: true,
                                handler() {
                                        this.fetch()
                                },
                        },
                },

                methods: {
                        fetch() {
                                this.$store.dispatch('SET_LOADING_AXIOS', 2500);
                                return new Promise((resolve, reject) => {
                                        GetRepository.get()
                                                .then(resp => {
                                                        this.data = resp.data.data;
                                                        this.page_settings = resp.data.data.page_settings;
                                                        this.title = this.data.page.meta_title;
                                                        this.og = this.data.meta.og.meta;
                                                        resolve(resp);
                                                })
                                                .catch(err => {
                                                        reject(err);
                                                });
                                });
                        },
                },

                metaInfo() {
                        return {
                                title: this.title,
                                meta: this.og
                        }
                },

        }
</script>

<style scoped lang="scss">
        @import '../assets/scss/index.scss';

        .home {
                @include sectionGlobal;

                & .item {
                        @include partnerItemImg
                }
        }
</style>